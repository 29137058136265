import {Injectable, Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CanonicalUrlService {

  canonicalUrlHtml: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private doc) {

  }

  /**
   * Dynamically sets Canonical Url in the <head>
   * @param customizedCanonicalUrl optional string
   */
  setCanonicalUrl(customizedCanonicalUrl?: string): void {
    this.removeCanonicalUrl();
    this.canonicalUrlHtml = this.doc.createElement('link');
    this.canonicalUrlHtml.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(this.canonicalUrlHtml);
    // remove query string from current url
    const [currentUrlWithoutQueryString] = this.doc.URL.split('?');

    this.canonicalUrlHtml.setAttribute('href', (customizedCanonicalUrl === undefined || customizedCanonicalUrl === null) ? currentUrlWithoutQueryString : customizedCanonicalUrl);
  }


  /**
   * Removes the added Canonical URL element if it exists
   */
  removeCanonicalUrl(): void{
    if (this.canonicalUrlHtml) {
      this.canonicalUrlHtml.remove();

    }
  }


}
