<form *ngIf="form" [formGroup]="form" class="find-locations" novalidate>
    <div class="form-container">
      <h1 class="title">find a blink fitness gym</h1>
      <h2 class="subtitle">We have 100+ gyms. Chances are there's one near you.</h2> 
      <div class="search-location-pt-container">
        <input #inputRef type="text" class="search-location-pt-input"
          placeholder="Search by City, Zip or State"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          autocomplete="off"
          formControlName="address"
          (keydown.enter)="onEnterPress()"
          aria-label="Search location by City, Zip or State"/>   
        <button type="button" class="btn search-location-pt-btn" tabindex="-1" (click)="onSubmitFn()">
          <img alt="Search" src="./assets/img/white-search.svg">
        </button>  
        <button class="search-arrow" tabindex="-1" (click)="onSubmitFn()">
        </button>   
      </div>
    </div>
</form>
