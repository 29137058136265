import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '@environment';
import { Post, Category, Tag, HeaderLinks, Pagination, GetPostsParams } from '@models';

/**
 * Cms Service
 */
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  /**
   * @ignore
   */
  private _headerLinks = new BehaviorSubject<HeaderLinks>(null);
  /**
   * @ignore
   */
  readonly headerLinks$ = this._headerLinks.asObservable();

  /**
   * constructor CmsService
   * @param httpClient
   */
  constructor(private httpClient: HttpClient) {
  }

  async loadHeaderLinks(): Promise<void> {
    const data = await this.getDataSetBySlugNameWebCms('header-links').toPromise();

    this._headerLinks.next({ ...data });
  }

  /**
   * @param {GetPostsParams} params
   */
  getPostsWithParam(params: GetPostsParams): Observable<Post[]> {
    const getPostsParams = {
      offset: '0',
      per_page: '10',
      page: '1',
      context: 'embed',
      orderby: 'date',
      ...params
    };
    const options = {
      params: new HttpParams({fromObject: getPostsParams})
    };
    return this.httpClient.get<Post[]>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * @param params
   */
  getPostsWithParamWithResponse(params: GetPostsParams): Observable<HttpResponse<any>> {
    const getPostsParams = {
      offset: '0',
      per_page: '10',
      page: '1',
      orderby: 'date',
      ...params
    };
    const options = {
      observe: 'response' as 'body',
      params: new HttpParams({fromObject: getPostsParams})
    };
    return this.httpClient.get<HttpResponse<any>>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * Get All posts from CMS
   * @returns Observable<Post[]>
   */
  getAllPosts(): Observable<Post[]> {
    const options = {params: new HttpParams().set('context', 'embed')};
    return this.httpClient.get<Post[]>(`${environment.API_CONF.CMS_API}/posts`, options);
  }

  /**
   * Get post by Slug
   * @param slug
   * @returns Observable<Post>
   */
  getPostBySlug(slug: string): Observable<Post> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/posts/slug/${slug}/`).pipe(map(res => res[0]));
  }

  /**
   * Get post by ID
   * @param id
   * @returns Observable<Post>
   */
  getPostById(id: number): Observable<Post> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/posts/${id}/`);
  }

  /**
   * Get Category By Slug
   * @returns Observable<Category[]>
   */
  getCategoryBySlug(category_slug): Observable<Category[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/categories?slug=${category_slug}`);
  }

  /**
   * Get All Categories
   * @returns Observable<Category[]>
   */
  getAllCategories(): Observable<Category[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/categories/`);
  }

  /**
   * Get Post Tags
   * @param {number[]} tag_ids
   * @returns Observable<Tag[]>
   */
  getPostTags(tag_ids: number[]): Observable<Tag[]> {
    const tags = tag_ids.join(', ');
    const options = {params: new HttpParams().set('include', tags)};
    return this.httpClient.get<Tag[]>(`${environment.API_CONF.CMS_API}/tags`, options);
  }

  /**
   * Get Tag By Slug
   * @param {string} tag_slug
   * @returns Observable<Tag[]>
   */
  getTagBySlug(tag_slug: string): Observable<Tag[]> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/tags?slug=${tag_slug}`);
  }

  /**
   * Get Data Set By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
  getDataSetBySlugNameWebCms(slug: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_CONF.WEB_CMS_API}/data-sets/slug/${slug}`).pipe(map(data => data[0]));
  }

  /**
   * Get Data Set By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
  getPageBySlug<T>(slug: string): Observable<T> {
    return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/pages/slug/${slug}`).pipe(map(data => data[0]));
  }

  /**
   * Get Page By Slug From Website CMS
   * @param {string} slug
   * @returns Observable<any>
   */
   getPageBySlugNameWebCms<T>(slug: string): Observable<T> {
    return this.httpClient.get<T>(`${environment.API_CONF.WEB_CMS_API}/pages/slug/${slug}`).pipe(map(data => data[0]));
  }
}
