export const API_URL = 'https://qa.api.sls.blinkfitness.com';

export const environment = {
  production: false,
  isDebugMode: true,
  AWS_CONF: {
  },
  API_CONF: {
    CMS_API: API_URL + '/blog.v3.qa',
    WEB_CMS_API: API_URL + '/web.v3.qa',
    X_API_KEY: 'no1live4everno1live4everqa',
  },
  blinkWebDomain: 'http://team-noho-qa-blinkweb.dev.sls.blinkfitness.com',
  yextLocationsDomain: 'https://locations-qa_blinkfitness_com.yextpages.net',
  domain: 'https://qa.press.blinkfitness.com',
  websiteDomain: 'https://www.blinkfitness.com',
  iblinkDomain: 'https://qa.account.blinkfitness.com',
  blinkfitnessSocialUrl: `https://media.cms.blinkfitness.com/media/social-madia-images/blinkBackground.png`,
  adobeDTMSnippet: "https://assets.adobedtm.com/0a037a69b158/b8ed178654fd/launch-dec72d2ad6c0-development.min.js",
  domainLocations: 'https://locations.blinkfitness.com'
};
