import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import { environment } from '../../../environments/environment'
import { BehaviorSubject } from 'rxjs';

declare let dataLayer: any;
declare let tagData: any;

@Injectable()
export class AnalyticsService {
  protected initialLoad = false;
  protected currentPageEvent = {
    event: 'Pageview',
    loginStatus: 'out',
    pageArea: 'pub',
    pageItem: 'blinkfitness',
    pageSection: 'press',
    experience: 'v2',
    pageType: 'Press Page'
  };



  private injectAnalytics = new BehaviorSubject<boolean>(false);
  readonly injectAnalytics$ = this.injectAnalytics.asObservable();
  adobeScript: HTMLScriptElement;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public trackPageView(page: string) {
    this.trackGTM({ page });
    this.trackAdm({ page });
  }

  protected trackGTM(data: { page: string }) {
    if (this.initialLoad) {
      remove(dataLayer, (elem: any) => ! includes(elem.event, 'gtm.'));
      dataLayer.push({
        ...this.currentPageEvent,
        pageItem: data.page || 'blinkfitness'
      });
    } else {
      this.initialLoad = true;
    }
  }

  protected trackAdm(data: { page: string }) {
      tagData.global.pageItem = (data && data.page) || 'blinkfitness';
  }

  loadAdobeDTM() {
    if (this.adobeScript == null){
      const dynamicScript = environment.adobeDTMSnippet;
      this.adobeScript = this.document.createElement('script');
      this.adobeScript.src = dynamicScript;
      this.adobeScript.type = 'text/javascript';
      this.adobeScript.async = false;
      this.adobeScript.defer = true;
      this.adobeScript.charset = 'utf-8';
      this.document.getElementsByTagName('head')[0].appendChild(this.adobeScript);
    }
  }

  loadAnalytics(){
    this.injectAnalytics$.subscribe((data) => {
      const injectAnalytics = data;
      if(injectAnalytics){
        this.loadAdobeDTM();
      }
    });
  }

  updateInjectAnalytics(value: boolean) {
    this.injectAnalytics.next(value);
  }

}
