import { Component } from '@angular/core';
import { TitleService } from './core/services/other/title.service';
import { NavigationEnd, Router } from '@angular/router';
import { CmsService } from './core/services/cms.service';
import { AnalyticsService } from './core/services/analytics.service';
import { CanonicalUrlService } from '@core/services/canonical-url.service';

@Component({
  selector: 'blb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Blink-Press';
  public route = 'index';

  constructor(
    private titleService: TitleService,
    private cmsService: CmsService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private canonicalUrlService: CanonicalUrlService
  ) {
    this.cmsService.loadHeaderLinks();
    this.titleService.init();
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.route = route.url;
        this.canonicalUrlService.setCanonicalUrl();
        this.analyticsService.trackPageView(route.url.replace(/^\/+/g, ''));
        if (this.route && this.route.length > 0) {
          this.route = this.route === '/' ? 'archive' : 'single ' + this.route.slice(1);
        }
      }
    });

    this.analyticsService.loadAnalytics();
  }
}
