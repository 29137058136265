import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './core/layout/templates/main/main.component';
import {P404Component} from './public-pages/other/404.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () => import('./public-pages/public-pages.module').then(mod => mod.PublicPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
