import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {LoggerService} from './log4ts/logger.service';

@Injectable()
export class SeoService {

  constructor(private logger: LoggerService, private meta: Meta, private titleService: Title) {

  }

  generateTags(config?) {
    // default values
    config = {
      title: 'Press, News & Media | Blink Fitness',
      description: 'Read the latest press releases and news from Blink Fitness gyms located in New York City, California, Houston, Chicago and throughout the United States.',
      image: environment.blinkfitnessSocialUrl,
      url: environment.domain,
      slug: 'BlinkFitness',
      author: 'Blinkfitness.com',
      keywords: 'fitness, gym, blink gym, workout, training, best value, working out, weights, training, strength equipment, cardio equipment, gym near me, cheap gym, gym membership, affordable gym, healthy, fit, affordable gym, clean gym, personal training, boot camp, free trial, new york city',
      ...config
    };


    this.meta.updateTag({name: 'keywords', content: config.keywords});
    this.meta.updateTag({name: 'author', content: config.author});
    this.meta.updateTag({name: 'description', content: config.description});
    this.titleService.setTitle(config.title);

    // http://ogp.me/
    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: config.url});
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});

    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({property: 'og:site_name', content: config.author});
    this.meta.updateTag({property: 'og:title', content: config.title});
    this.meta.updateTag({property: 'og:description', content: config.description});
    this.meta.updateTag({property: 'og:image', content: config.image});
    this.meta.updateTag({property: 'og:url', content: config.url});
  }


}
