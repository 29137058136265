import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormRules } from './find-locations.validations';
import { environment } from '../../../../environments/environment';
import { constants } from '@core/const/constants';

/**
 * Find Locations Component.
 * Input with autocomplete to Gyms
 */
@Component({
  selector: 'bw-find-locations',
  templateUrl: './find-locations.component.html',
  styleUrls: ['./find-locations.component.scss'],
})

export class FindLocationsComponent implements OnInit {
  @Input() searchImage: boolean = false;
  regionList = constants.statesList;
  window: Window = window; // This is necessary for unity test
  form: FormGroup;
  @ViewChild('inputRef') inputElement: ElementRef;

  /**
   * @constructor
   * @param fb
   */
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) { }

  /**
   * Lifecycle OnInit
   */
  ngOnInit() {
    this.form = this.fb.group(FormRules);
  }

  /**
   * Function to submit search form data
   */
  onEnterPress(): void {
    this.onSubmitFn();
  }

  /**
   * Form submit event
   */
  onSubmitFn(): void {
    const address = this.form?.controls?.address?.value || '';
    var existingLocation = this.regionList.find(l => l.abbreviation.toUpperCase() == address.toUpperCase()
      || l.name.toUpperCase() == address.toUpperCase());
    if (existingLocation != null) {
      this.window.location.href = existingLocation.href;
    }
    else {
      const param = address ? `?q=${encodeURI(address)}` : '';
      this.window.location.href = `${environment.domainLocations}/search${param}`;
    }
  }
}
