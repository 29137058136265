import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './templates/main/main.component';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {BlinkNavigationComponentsAngularModule} from '@blinkfitness/navigation-components';
import {environment} from '@environment';
import { FindLocationsModule } from '@shared/components/find-locations/find-locations.module';

@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FlexModule,
    FlexLayoutModule,
    BlinkNavigationComponentsAngularModule.forRoot({
      production: environment.production,
      API_CONF: {
          CMS_API: environment.API_CONF.WEB_CMS_API,
          X_API_KEY: environment.API_CONF.X_API_KEY,
      },
      blinkWebsiteDomain: environment.blinkWebDomain,
      iblinkDomain: environment.iblinkDomain,
  }),
  FindLocationsModule
  ]
})
export class LayoutModule { }
