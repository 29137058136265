import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { P404Component } from '@public-pages/other/404.component';
import { P500Component } from '@public-pages/other/500.component';
import { LayoutModule } from './layout/layout.module';
import { AuthInterceptor } from './services/core/auth-interceptor';
import { CmsService } from './services/cms.service';
import { LocalStorageService } from './services/other/local-storage.service';
import { LoggerService } from './services/other/log4ts/logger.service';
import { ConsoleLoggerService } from './services/other/log4ts/console-logger.service';
import { SeoService } from './services/other/seo.service';
import { SidenavService } from './services/other/sidenav.service';
import { PopupService } from './services/popup.service';
import { BlogService } from './services/blog.service';

@NgModule({
  declarations: [
    P404Component,
    P500Component
  ],
  imports: [
    CommonModule,
    LayoutModule,
    HttpClientModule,
    FlexModule
  ],
  exports: [
    P404Component,
    P500Component
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LoggerService, useClass: ConsoleLoggerService },
    SeoService,
    CmsService,
    PopupService,
    SidenavService,
    LocalStorageService,
    BlogService
  ]
})
export class CoreModule {
}
